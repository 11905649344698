export enum ELinkColor {
  TG = 'tg',
  TG_OUTLINE = 'tg-outline',
  YELLOW = 'yellow',
}
export enum ELinkIcon {
  ARROW = 'arrow',
  LINK = 'link',
  TG = 'tg',
}
